<!--
 * @Description: 
 * @Author: chli16
 * @Date: 2023-01-12 09:42:28
 * @LastEditTime: 2023-03-15 16:09:35
 * @FilePath: \LanhuProject\src\views\lanhu_v\index.vue
-->
<template>
  <div class="page flex-col">
    <div class="section_1 flex-row">
      <div class="group_1 flex-col">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/yunmiaopai.png"
        />
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng17661ae57eb29517a30c95b5a918ae91498592bcc8358c50783cef9359bdfb62.png"
        />
        <span class="paragraph_1">
          &nbsp;一款基于人工智能算法和互联网技术的创新型应用软件，通过AI技术为您提供农业知识。
          <br />
          &nbsp;农业知识智能问答的核心引擎采用人工智能算法，根据用户提问，智能返回相关回答，支持语音播报，满足不同人群的需求。
          <br />
          &nbsp;病虫草害拍照智能识别采用AI算法模型，通过建立病虫草害数据集，搭配机器学习和图像识别技术，达到一键拍照，快速识别的效果。
          <br />
          &nbsp;资讯板块为您提供农业领域新闻资讯和通知公告等，在这里您可以看到行业内新闻和各类活动通知。
        </span>
      </div>
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngcf0180b6d868b1dfc0cd176e27984cb8333c174d1629c127e6fd58748255b4fb.png"
      />
    </div>
    <div class="bah">
      <div class="code" @click="openLink">豫ICP备2023002555号-1</div>
      <div class="img"></div>
      <div class="code" @click="openLinkGA">豫公网安备 41010502005679号</div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        constants: {},
      };
    },
    methods: {
      openLink() {
        window.open("https://beian.miit.gov.cn/");
      },
      openLinkGA() {
        window.open(
          "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010502005679"
        );
      },
    },
  };
</script>
<style scoped lang="less" src="./assets/index.less" />
