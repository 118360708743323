<template>
  <div id="app" ref="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    methods: {
      getDivHeight() {
        const screenheight = window.innerHeight;
        this.$refs.app.style.height = screenheight + "px";
        // 704 为 头部组件高度 + 底部组件高度
        // mainDiv 为需控制高度自适应盒子，通过 ref="mainDiv" 绑定
      },
    },
    // 在 mounted 生命周期监听窗口变化并触发上文处理函数，修改高度
    mounted() {
      this.getDivHeight();
      window.addEventListener("resize", this.getDivHeight);
    },
    destroyed() {
      window.removeEventListener("resize", this.getDivHeight, false);
    },
  };
</script>

<style lang="less" src="./assets/common.less"></style>
